.select {
    .select2-container--default .select2-selection--single {
        border: 1px solid #ffffff;
        border-radius: 2px;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 24px 0 32px;
        background: #ffffff;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: rgba(83, 85, 99, 0.6);
        font-size: 18px;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        padding-left: 0;
        flex-grow: 1;
        min-width: 0;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        display: none;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        position: static;
        width: 24px;
        height: 24px;
        background-image: url('img/select-arrow.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        transition: 0.3s ease-in-out;
    }

    .select2-container--default.select2-container--open .select2-selection__arrow {
        transform: rotate(180deg);
    }

    &__dropdown {
        font-size: 18px;
        position: relative;

        .select2-dropdown {
            border: 1px solid rgba(0, 0, 0, 0.06);
            overflow: hidden;
            background: #ffffff;
            color: rgba(83, 85, 99, 0.6);
            padding: 12px 0;

            &.select2-dropdown--below {
                border-radius: 0;
            }

            &.select2-dropdown--above {
                border-radius: 0;
            }
        }

        .select2-results__option {
            padding: 12px 50px 12px 32px;
            transition: 0.3s ease-in-out;
            position: relative;

            &::after {
                width: 24px;
                height: 24px;
                background-image: url('img/checked.svg');
                background-repeat: no-repeat;
                background-size: 100%;
                transition: 0.3s ease-in-out;
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 24px;
                opacity: 0;
            }
        }

        .select2-container--default {
            .select2-results__option--selected {
                background-color: #ffffff;
                color: #56AEF8;

                &::after {
                    opacity: 1;
                }
            }

            .select2-results__option--highlighted.select2-results__option--selectable {
                background-color: #ffffff;
                color: #56AEF8;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .select2-container--default .select2-selection--single {
            height: 36px;
            padding: 0 24px;
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered {
            font-size: 14px;
        }

        .select2-container--default .select2-selection--single .select2-selection__arrow b {
            display: none;
        }

        .select2-container--default .select2-selection--single .select2-selection__arrow {
            width: 20px;
            height: 20px;
        }

        &__dropdown {
            font-size: 14px;
            position: relative;

            .select2-dropdown {
                padding: 8px 0;
            }

            .select2-results__option {
                padding: 8px 50px 8px 24px;
                transition: 0.3s ease-in-out;
                position: relative;

                &::after {
                    width: 14px;
                    height: 14px;
                    right: 20px;
                }
            }
        }
    }
}