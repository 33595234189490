.certificates-slider {

    &__content {
        max-width: 368px;
        padding-bottom: 1px;
    }


    @media screen and (max-width: 768px) {
        &__content {
            max-width: none;
            display: flex;
            flex-direction: column;

            .slider__nav {
                order: -1;
                margin: 16px 0 24px;
                justify-content: center;
            }
        }
    }
}