.main-tabs {
    display: flex;

    &__menu {
        width: 33.3333%;
        min-width: 33.3333%;
        padding-top: 162px;
    }

    &__item {
        max-width: 216px;
        margin: 0 auto 20px;
        border-bottom: 1px solid transparent;
        padding-bottom: 4px;
        transition: 0.3s ease-in-out;

        &.is-active {
            border-color: #FFC448;

            .main-tabs__link {
                opacity: 1;
                text-decoration: none;
            }
        }
    }

    &__link {
        display: inline-block;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        color: #202126;
        opacity: 0.5;
        text-decoration: underline;
        font-weight: 500;
        font-size: 24px;

        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }

    &__content {
        flex-grow: 1;
        min-width: 0;
        position: relative;
        max-width: 632px;
    }

    &__image {
        position: absolute;
        bottom: -50px;
        left: 0;
        transform: translateX(-50%);
        max-width: 80%;
        pointer-events: none;
    }


    @media screen and (max-width: 992px) {
        &__menu {
            padding-top: 60px;
        }
    }

    @media screen and (max-width: 768px) {
        &__menu {
            min-width: 120px;
            padding-right: 16px;
        }

        &__link {
            font-size: 12px;
        }

        &__content {
            margin-right: -16px;
        }
    }
}