.button {
	display: inline-flex;
	cursor: pointer;
	transition: 0.3s ease-in-out;
	align-items: center;
	justify-content: center;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	padding: 0 40px;
	text-align: center;
	height: 66px;
	border: none;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 500;
	border-radius: 2px;
	position: relative;
	overflow: hidden;

	&__bg {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		transition: 0.3s ease-in-out;
		background: linear-gradient(90deg, #56AEF8 0%, #93CDFF 100%);
		z-index: 1;
	}

	&__text {
		position: relative;
		z-index: 2;
		display: block;
	}

	&_primary {
		color: #FFFFFF;
		background: linear-gradient(90deg, #93CDFF 0%, #56AEF8 100%);

		&:hover,
		&:active {
			.button__bg {
				opacity: 0;
			}
		}
	}

	&_full {
		width: 100%;
	}

	&_small {
		height: 50px;
		font-size: 16px;
	}

	@media screen and (max-width: 768px) {
		padding: 0 16px;
		height: 48px;
		font-size: 14px;
	}
}