.footer {
    padding: 90px 0 48px;

    &-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &__item {
            &:not(:first-child) {
                padding-left: 100px;
            }
        }

        &__link {
            display: inline-block;
            font-weight: 500;
            text-transform: uppercase;
            color: #535563;

            &:hover {
                color: #56AEF8;
            }
        }
    }

    &-line {
        background: #535563;
        opacity: 0.1;
        height: 2px;
    }

    &-copy {
        font-size: 10px;
        letter-spacing: 0.215385px;
        color: #1F2432;
        opacity: 0.4;
    }


    @media screen and (max-width: 992px) {
        &-menu {
            &__item {
                &:not(:first-child) {
                    padding-left: 40px;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 24px 0;
        text-align: center;

        &__logo {
            margin: 0 auto;
        }

        &-menu {
            display: block;
            padding-top: 10px;

            &__item {
                padding-top: 20px;

                &:not(:first-child) {
                    padding-left: 0;
                }
            }

            &__link {
                font-size: 12px;
            }
        }

        &-line {
            display: none;
        }

        &-copy {
            margin-top: 8px;
        }
    }
}