.article {
    display: block;
    height: 100%;
    position: relative;

    &__image {
        height: 100%;
        position: relative;
        z-index: 1;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    &__layout {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 40px;
        background-image: url('img/image-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 3;
    }

    &__title {
        display: block;
        transition: 0.3s ease-in-out;
    }

    &__text {
        display: block;
        transition: 0.3s ease-in-out;
    }

    &:hover {

        .article__title,
        .article__text {
            transform: translateY(-10px);
        }
    }

    @media screen and (max-width: 992px) {
        height: 200px;

        &__layout {
            padding: 24px;
            justify-content: center;
        }
    }

    @media screen and (max-width: 576px) {
        height: 94px;

        &__text {
            display: none;
        }
    }
}