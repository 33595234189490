.schedule {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 60px 0;

    &__image {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 50%;

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &-slider {
        padding: 0 76px;
        margin: 0 -76px 15px;
        height: 78px;

        &::after {
            content: '';
            background: #ffffff;
            width: 76px;
            top: 0;
            bottom: 0;
            z-index: 2;
            left: 0;
            position: absolute;
        }

        &::before {
            content: '';
            background: #ffffff;
            width: 76px;
            top: 0;
            bottom: 0;
            z-index: 2;
            right: 0;
            position: absolute;
        }

        &__date {
            font-weight: 500;
            font-size: 20px;
            color: #535563;
            opacity: 0.4;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            position: relative;
            padding-bottom: 16px;
            position: relative;
            text-align: center;

            &::after {
                position: absolute;
                left: 0;
                bottom: -4px;
                height: 4px;
                background: #FFC448;
                width: 0;
                transition: 0.3s ease-in-out;
                content: '';
            }

            &:hover {
                opacity: 0.8;

                &::after {
                    width: 100%;
                }
            }
        }

        &__item {
            border-bottom: 4px solid rgba(83, 85, 99, 0.1);

            &.swiper-slide-active {
                .schedule-slider__date {
                    opacity: 0.8;

                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-text-slider {
        margin-bottom: 40px;
        min-height: 136px;
    }

    &__content {
        padding-left: 100px;
    }

    @media screen and (max-width: 1200px) {
        &__content {
            padding-left: 40px;
        }

        &-slider {
            height: auto;
            padding: 0 0;
            margin: 0 0 20px;

            &::after {
                display: none;
            }

            &::before {
                display: none;
            }

            .slider__arrow {
                display: none;
            }
        }

        &-text-slider {
            min-height: 0;
        }
    }

    @media screen and (max-width: 992px) {
        background: #F3F7FB;
        min-height: 0;
        padding-bottom: 0;
        display: block;

        &__content {
            padding-left: 0;
        }

        &__image {
            position: static;
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &-slider {
            &__date {
                font-size: 14px;
            }
        }
    }
}