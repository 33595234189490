.search {
    display: flex;
    align-items: center;
    padding: 8px 0;

    &__wrapper {
        flex-grow: 1;
        min-width: 0;
        padding: 0 12px;
    }

    &__input {
        height: 42px;
        background: transparent;
        border: 1px solid rgba(160, 162, 163, 0.6);
        border-radius: 2px;
        font-size: 13px;
        padding: 0 48px 0 16px;
        font-family: inherit;
        transition: 0.3s;
        display: block;
        width: 100%;
        color: #535563;

        &::-webkit-input-placeholder {
            color: #535563;
            opacity: 0.6;
        }

        &::-moz-placeholder {
            color: #535563;
            opacity: 0.6;
        }

        &:-moz-placeholder {
            color: #535563;
            opacity: 0.6;
        }

        &:-ms-input-placeholder {
            color: #535563;
            opacity: 0.6;
        }

        &::placeholder {
            color: #535563;
            opacity: 0.6;
        }

        &:focus {
            border-color: #535563;
        }
    }

    &__button {
        background: transparent;
        border: none;
        width: 18px;
        min-width: 18px;
        height: 18px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        color: #535563;
        opacity: 0.6;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;

        &:hover {
            opacity: 1;
        }
    }

    &-block {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #F3F7FB;
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
        display: none;
        transition: 0.3s ease-in-out;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);

        &.is-visible {
            display: block;
        }

        &.is-show {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }
    }

    &-form {
        position: relative;
    }

    &-results {
        padding: 12px 0 32px;
        max-height: calc(100vh - 150px);
        overflow-y: auto;

        &__item {
            margin-top: 20px;
            display: flex;
        }

        &__image {
            width: 52px;
            min-width: 52px;
            margin-right: 20px;
        }
    }

    @media screen and (max-width: 992px) {
        &__input {
            border: none;
            border-bottom: 1px solid rgba(160, 162, 163, 0.6);
            padding: 0 8px;
            border-radius: 0;
        }

        &__button {
            position: static;
            transform: translate(0, 0);
        }
    }
}