.main-contacts {
    min-height: 555px;
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: linear-gradient(179.26deg, rgba(255, 255, 255, 0.2) 0.64%, rgba(255, 255, 255, 0) 15.76%), linear-gradient(328.77deg, #F3F5F9 0%, #FFFFFF 101.51%);
        opacity: 0.56;
        z-index: 1;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-image: url('img/contacts-bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100% 100%;
        z-index: 2;
        opacity: 0.56;
    }

    .content {
        position: relative;
        z-index: 3;
    }

    &__top {
        margin-bottom: 100px;
    }

    &__line {
        display: flex;
    }

    &__name {
        width: 136px;
        min-width: 136px;
        padding-right: 12px;
    }

    @media screen and (max-width: 768px) {
        min-height: 0;

        &__top {
            margin-bottom: 35px;
        }

        &__name {
            width: 88px;
            min-width: 88px;
        }
    }
}