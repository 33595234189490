.main-page {
    @media screen and (min-width: 993px) {
        .header {
            &:not(.is-up) {
                .header-menu__link {
                    color: rgba(255, 255, 255, 0.6);

                    &:hover,
                    &.is-active {
                        color: #56AEF8;
                    }
                }

                .lang {
                    color: #ffffff;

                    &.is-active {
                        color: #535563;
                    }
                }

                .header__logo {
                    color: #ffffff;
                }

                .search {
                    &__input {
                        border-color: rgba(255, 255, 255, 0.6);
                        color: #ffffff;

                        &::-webkit-input-placeholder {
                            color: #ffffff;
                        }

                        &::-moz-placeholder {
                            color: #ffffff;
                        }

                        &:-moz-placeholder {
                            color: #ffffff;
                        }

                        &:-ms-input-placeholder {
                            color: #ffffff;
                        }

                        &::placeholder {
                            color: #ffffff;
                        }

                        &:focus {
                            border-color: #ffffff;
                        }
                    }

                    &__button {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}