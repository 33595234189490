.form {
    &__label {
        display: block;
        font-weight: 400;
        font-size: 15px;
        opacity: 0.9;
        line-height: 1.8;
    }

    label.error {
        display: none !important;
    }

    @media screen and (max-width: 768px) {
        &__label {
            font-size: 13px;
        }
    }
}