.h1 {
	font-weight: 300;
	font-size: 60px;
}

.h2 {
	font-weight: 300;
	font-size: 40px;
}

.h3 {
	font-size: 24px;
	font-weight: 400;
}

.fs-32 {
	font-size: 32px;
}

.fs-20 {
	font-size: 20px;
}

.fs-18 {
	font-size: 18px;
}

.fs-12 {
	font-size: 12px;
}

@media screen and (max-width: 992px) {
	.fs-t-32 {
		font-size: 32px;
	}
}

@media screen and (max-width: 768px) {
	.h1 {
		font-size: 36px;
	}

	.h2 {
		font-size: 28px;
	}

	.h3 {
		font-size: 18px;
	}

	.fs-m-18 {
		font-size: 18px;
	}

	.fs-m-16 {
		font-size: 16px;
	}

	.fs-m-14 {
		font-size: 14px;
	}

	.fs-m-12 {
		font-size: 12px;
	}
}