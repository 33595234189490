// margin
.mt-8 {
    margin-top: 8px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-48 {
    margin-top: 48px;
}

.mt-64 {
    margin-top: 64px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-52 {
    margin-bottom: 52px;
}

@media screen and (max-width: 992px) {
    .mt-t-32 {
        margin-top: 32px;
    }
}

@media screen and (max-width: 768px) {
    .mb-m-0 {
        margin-bottom: 0;
    }

    .mb-m-8 {
        margin-bottom: 8px;
    }

    .mb-m-20 {
        margin-bottom: 20px;
    }

    .mb-m-24 {
        margin-bottom: 24px;
    }

    .mt-m-0 {
        margin-top: 0;
    }

    .mt-m-12 {
        margin-top: 12px;
    }

    .mt-m-20 {
        margin-top: 20px;
    }

    .mt-m-60 {
        margin-top: 60px;
    }

}

// padding

.pb-16 {
    padding-bottom: 16px;
}

.pt-16 {
    padding-top: 16px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-24 {
    padding-top: 24px;
}

.pt-60 {
    padding-top: 60px;
}

@media screen and (max-width: 768px) {
    .pt-m-16 {
        padding-top: 16px;
    }
}