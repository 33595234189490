.group {
    &__image {
        display: flex;

        &_right {
            margin-left: -10px;
            justify-content: flex-start;
        }

        &_left {
            margin-right: -10px;
            justify-content: flex-end;
        }

        img {
            display: block;
            width: 50vw;
            min-width: 50vw;
        }
    }

    &__content {
        height: 100%;
        width: 368px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media screen and (max-width: 922px) {
        &__content {
            width: 100%;
        }

        &__image {
            display: flex;

            &_full {
                margin: 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &__image {
            display: block;

            &_half {
                margin: 0;
                height: 100%;

                img {
                    width: 100%;
                    min-width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }

            &_full {
                margin-left: -16px;
                margin-right: -16px;
                position: relative;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(180deg, #F3F7FB 0%, rgba(243, 247, 251, 0.94) 10.73%, rgba(243, 247, 251, 0) 100%);
                    height: 60%;
                    content: '';
                }

                img {
                    width: 100%;
                    min-width: 100%;
                }
            }
        }
    }
}