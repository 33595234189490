.soc {
    display: flex;
    margin: 0 -15px;

    &__item {
        padding: 0 15px;
    }

    &__link {
        display: block;
        width: 24px;
        min-width: 24px;
        height: 24px;
    }

    @media screen and (max-width: 768px) {
        justify-content: center;
    }
}