.burger {
    cursor: pointer;

    &__line {
        transition: 0.3s ease-in-out;
        width: 22px;
        height: 2px;
        background: #56AEF8;
        margin: 5px 0;
        position: relative;

        &:first-child {
            top: 0;
        }

        &:nth-child(2) {
            opacity: 1;
        }

        &:nth-child(3) {
            top: 0;
        }
    }

    &:hover {
        opacity: 0.7;
    }

    &.is-active {
        .burger__line {
            &:first-child {
                top: 7px;
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                top: -7px;
                transform: rotate(-45deg);
            }
        }
    }
}