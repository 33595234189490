.error-page {
    min-height: 100vh;
    display: flex;
    align-items: center;

    &__logo {
        margin-bottom: 95px;
    }

    &__text {
        margin-bottom: 50px;
    }

    @media screen and (max-width: 576px) {
        &__text {
            max-width: 280px;
            margin-left: auto;
            margin-right: auto;

            .button {
                width: 100%;
            }
        }

        &__image {
            margin-left: -16px;
            margin-right: -16px;
        }
    }
}