.gallery-slider {

    .swiper-slide {
        transition: 0.3s ease-in-out;
        padding: 24px 0;
        height: auto;

        &.swiper-slide-active {
            padding: 0;

            .gallery-slider__item {
                &::after {
                    opacity: 0;
                }
            }
        }
    }

    &__item {
        height: 100%;
        position: relative;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(24, 24, 24, 0.7);
            content: '';
            transition: 0.3s ease-in-out;
        }
    }

    @media screen and (max-width: 768px) {
        .swiper-slide {
            padding: 8px 0;
        }
    }
}