.input {
    height: 42px;
    padding: 0 24px;
    border: 1px solid #535563;
    background: #E2E2E2;
    border-radius: 2px;
    font-size: 13px;
    color: #535563;
    font-family: 'Rubik', sans-serif;
    transition: 0.3s;
    display: block;
    width: 100%;

    &::-webkit-input-placeholder {
        opacity: 0.3;
    }

    &::-moz-placeholder {
        opacity: 0.3;
    }

    &:-moz-placeholder {
        opacity: 0.3;
    }

    &:-ms-input-placeholder {
        opacity: 0.3;
    }

    &::placeholder {
        opacity: 0.3;
    }

    &_white {
        border-color: #ffffff;
        background: #ffffff;
    }

    &:focus {
        border-color: #007BBC;
    }

    &.error {
        border-color: red;
    }

    &_text {
        height: 165px;
        padding: 14px 24px;
        resize: none;
    }

    @media screen and (max-width: 768px) {
        font-size: 12px;

        &_text {
            height: 103px;
        }
    }
}