.g-row {
	display: flex;
	margin-left: -10px;
	margin-right: -10px;

	&_between {
		justify-content: space-between;
	}

	&_middle {
		align-items: center;
	}

	&_wrap {
		flex-wrap: wrap;
	}

	&_reverse {
		flex-direction: row-reverse;
	}

	&_bottom {
		align-items: flex-end;
	}

	&_center {
		justify-content: center;
	}

	&_column {
		flex-direction: column;
	}

	&_end {
		justify-content: flex-end;
	}

	@media screen and (max-width: 992px) {
		&_t-wrap {
			flex-wrap: wrap;
		}
	}

	@media screen and (max-width: 768px) {
		&_m-wrap {
			flex-wrap: wrap;
		}

		&_m-reverse {
			flex-direction: row-reverse;
		}
	}

	@media screen and (max-width: 576px) {
		&_s-wrap {
			flex-wrap: wrap;
		}
	}
}

.g-col {
	width: auto;
	padding-left: 10px;
	padding-right: 10px;

	&_25 {
		width: 25%;
		min-width: 25%;
	}

	&_33 {
		width: 33.3333%;
		min-width: 33.3333%;
	}

	&_40 {
		width: 40%;
		min-width: 40%;
	}

	&_50 {
		width: 50%;
		min-width: 50%;
	}

	&_60 {
		width: 60%;
		min-width: 60%;
	}

	@media screen and (max-width: 992px) {
		&_t-33 {
			width: 33.3333%;
			min-width: 33.3333%;
		}

		&_t-100 {
			width: 100%;
			min-width: 100%;
		}
	}

	@media screen and (max-width: 768px) {
		&_m-100 {
			width: 100%;
			min-width: 100%;
		}
	}

	@media screen and (max-width: 576px) {
		&_s-100 {
			width: 100%;
			min-width: 100%;
		}

		&_s-50 {
			width: 50%;
			min-width: 50%;
		}
	}
}