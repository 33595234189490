* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: 0; 
}
a {
	text-decoration: none;
	color: inherit;
	transition: all 0.3s;
	&:hover {
		text-decoration: none;
	}
	&:active,
	&:hover,
	&:focus {
		outline: none;
	}
}
img {
	border: none;
	max-width: 100%;
	height: auto;
}
	
header,
section,
footer,
aside,
nav,
article,
figure {
	display: block;
}
input,
button,
textarea {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
}
input,
button,
textarea,
input:active,
input:focus,
textarea:active,
textarea:focus {
	outline: none;
	-webkit-appearance: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	border: none;
	outline: none;
}
table {
	border-spacing: 0;
	border-collapse: collapse;
}