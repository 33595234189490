.download {
    position: relative;
    background: #4CACFC;

    &__content {
        height: 778px;
        padding: 78px 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(149.22deg, #4CACFC 0%, #87C7FD 59.35%, rgba(255, 255, 255, 0.35) 96.19%);
    }

    &__text {
        max-width: 190px;
    }

    &__button {
        display: block;
        transition: 0.3s ease-in-out;
        background: #FFC448;
        border: 1px solid #FFC448;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        color: #ffffff;

        svg {
            width: 30px;
            height: 30px;
        }

        &:hover {
            color: #FFC448;
            background: #ffffff;
        }
    }

    &__top {
        position: relative;
    }

    &__title {
        transition: 0.3s ease-in-out;
    }

    &-search {
        &__button {
            width: 26px;
            height: 26px;
            min-width: 26px;
            border: none;
            background: none;
            cursor: pointer;
            transition: 0.3s ease-in-out;
        }

        &__form {
            width: 0;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.3s ease-in-out;
            display: flex;
            align-items: center;
            padding: 8px 0;
            border-bottom: 1px solid #ffffff;

            &.is-open {
                width: 100%;
            }
        }

        &__wrapper {
            flex-grow: 1;
            padding: 0 24px;
            min-width: 0;
        }

        &__input {
            height: 30px;
            background: transparent;
            border: none;
            padding: 0;
            border-radius: 0;
            font-weight: 300;
            font-size: 20px;
            color: #ffffff;

            &::-webkit-input-placeholder {
                color: #ffffff;
                opacity: 0.6;
            }

            &::-moz-placeholder {
                color: #ffffff;
                opacity: 0.6;
            }

            &:-moz-placeholder {
                color: #ffffff;
                opacity: 0.6;
            }

            &:-ms-input-placeholder {
                color: #ffffff;
                opacity: 0.6;
            }

            &::placeholder {
                color: #ffffff;
                opacity: 0.6;
            }
        }
    }

    &__link {
        display: inline-block;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__back {
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        margin-right: 12px;
    }

    &-group {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        &__button {
            margin-top: 36px;

            .download__button {
                margin-right: auto;
            }
        }
    }


    @media screen and (max-width: 992px) {
        &__content {
            padding: 24px 32px 24px 40px;
        }

        &__back {
            width: 24px;
            height: 24px;
            margin-right: 6px;
        }
    }

    @media screen and (max-width: 768px) {
        &__content {
            height: 442px;
            padding-right: 16px;
            padding-left: 32px;
        }

        &__text {
            max-width: 144px;
        }

        &__button {
            width: 56px;
            height: 56px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &-search {
            &__button {
                width: 16px;
                height: 16px;
                min-width: 16px;
            }

            &__form {
                padding: 4px 0;
            }

            &__wrapper {
                padding: 0 12px;
            }

            &__input {
                height: 24px;
                font-size: 12px;
            }
        }

        &__back {
            width: 18px;
            height: 18px;
        }

        &-group {
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__button {
                margin: 0 0 0 16px;
            }
        }
    }
}