.pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        padding: 0 12px;
    }

    &__arrow {
        cursor: pointer;
        width: 16px;
        height: 16px;
        min-width: 16px;

        &_prev {
            svg {
                transform: rotate(180deg);
            }
        }

        &.is-disabled {
            opacity: 0.2;
        }
    }

    &__link {
        font-size: 20px;
        cursor: pointer;
        opacity: 0.4;
        transition: 0.3s ease-in-out;

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}