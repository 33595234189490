@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
}

a:hover {
  text-decoration: none;
}

a:active, a:hover, a:focus {
  outline: none;
}

img {
  border: none;
  max-width: 100%;
  height: auto;
}

header,
section,
footer,
aside,
nav,
article,
figure {
  display: block;
}

input,
button,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

input,
button,
textarea,
input:active,
input:focus,
textarea:active,
textarea:focus {
  outline: none;
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
  outline: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

html {
  overflow-x: hidden;
}

html.is-fixed {
  overflow: hidden;
}

body {
  background: #ffffff;
  color: #535563;
  font-family: 'Rubik', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  overflow-x: hidden;
}

.wrap-all {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.wrap-content {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logo {
  display: block;
  width: 110px;
  height: 85px;
  transition: 0.3s ease-in-out;
}

.logo img {
  display: block;
}

.content {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1152px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 992px) {
  .t-hidden {
    display: none;
  }
}

.t-show {
  display: none;
}

@media screen and (max-width: 992px) {
  .t-show {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .m-hidden {
    display: none;
  }
}

.m-show {
  display: none;
}

@media screen and (max-width: 768px) {
  .m-show {
    display: block;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media screen and (max-width: 992px) {
  .text-t-center {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .text-m-center {
    text-align: center;
  }
}

.text-white {
  color: #ffffff;
}

.text-white-opacity {
  color: rgba(255, 255, 255, 0.8);
}

.text-yellow {
  color: #FFC448;
}

.text-blue {
  color: #56AEF8;
}

.text-green {
  color: #66C20F;
}

.text-dark-opacity {
  color: rgba(83, 85, 99, 0.8);
}

.text-grey-opacity {
  color: rgba(147, 150, 159, 0.9);
}

.uppercase {
  text-transform: uppercase;
}

.fw-medium {
  font-weight: 500;
}

.fw-light {
  font-weight: 300;
}

svg {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  color: inherit;
}

.section_pt {
  padding-top: 90px;
}

.section_pb {
  padding-bottom: 90px;
}

.section_bg {
  background: #F3F7FB;
}

.section_page {
  padding-top: 162px;
}

@media screen and (max-width: 992px) {
  .section_page {
    padding-top: 84px;
  }
}

@media screen and (max-width: 768px) {
  .section_pt {
    padding-top: 60px;
  }
  .section_pb {
    padding-bottom: 60px;
  }
  .section_m-pb {
    padding-bottom: 60px;
  }
  .section_m-bg {
    background: #F3F7FB;
  }
}

@media screen and (max-width: 576px) {
  .section_page {
    padding-top: 74px;
  }
}

.underline {
  display: inline-block;
  text-decoration: underline;
}

.link {
  color: #56AEF8;
}

.link:hover {
  opacity: 0.8;
}

.d-block {
  display: block;
}

.is-unvisible {
  opacity: 0;
}

.map {
  height: 555px;
}

@media screen and (max-width: 768px) {
  .map {
    height: 580px;
  }
}

.g-row {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.g-row_between {
  justify-content: space-between;
}

.g-row_middle {
  align-items: center;
}

.g-row_wrap {
  flex-wrap: wrap;
}

.g-row_reverse {
  flex-direction: row-reverse;
}

.g-row_bottom {
  align-items: flex-end;
}

.g-row_center {
  justify-content: center;
}

.g-row_column {
  flex-direction: column;
}

.g-row_end {
  justify-content: flex-end;
}

@media screen and (max-width: 992px) {
  .g-row_t-wrap {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .g-row_m-wrap {
    flex-wrap: wrap;
  }
  .g-row_m-reverse {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 576px) {
  .g-row_s-wrap {
    flex-wrap: wrap;
  }
}

.g-col {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.g-col_25 {
  width: 25%;
  min-width: 25%;
}

.g-col_33 {
  width: 33.3333%;
  min-width: 33.3333%;
}

.g-col_40 {
  width: 40%;
  min-width: 40%;
}

.g-col_50 {
  width: 50%;
  min-width: 50%;
}

.g-col_60 {
  width: 60%;
  min-width: 60%;
}

@media screen and (max-width: 992px) {
  .g-col_t-33 {
    width: 33.3333%;
    min-width: 33.3333%;
  }
  .g-col_t-100 {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .g-col_m-100 {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .g-col_s-100 {
    width: 100%;
    min-width: 100%;
  }
  .g-col_s-50 {
    width: 50%;
    min-width: 50%;
  }
}

.h1 {
  font-weight: 300;
  font-size: 60px;
}

.h2 {
  font-weight: 300;
  font-size: 40px;
}

.h3 {
  font-size: 24px;
  font-weight: 400;
}

.fs-32 {
  font-size: 32px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-12 {
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .fs-t-32 {
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .h1 {
    font-size: 36px;
  }
  .h2 {
    font-size: 28px;
  }
  .h3 {
    font-size: 18px;
  }
  .fs-m-18 {
    font-size: 18px;
  }
  .fs-m-16 {
    font-size: 16px;
  }
  .fs-m-14 {
    font-size: 14px;
  }
  .fs-m-12 {
    font-size: 12px;
  }
}

.button {
  display: inline-flex;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  padding: 0 40px;
  text-align: center;
  height: 66px;
  border: none;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.button__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  background: linear-gradient(90deg, #56AEF8 0%, #93CDFF 100%);
  z-index: 1;
}

.button__text {
  position: relative;
  z-index: 2;
  display: block;
}

.button_primary {
  color: #FFFFFF;
  background: linear-gradient(90deg, #93CDFF 0%, #56AEF8 100%);
}

.button_primary:hover .button__bg, .button_primary:active .button__bg {
  opacity: 0;
}

.button_full {
  width: 100%;
}

.button_small {
  height: 50px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .button {
    padding: 0 16px;
    height: 48px;
    font-size: 14px;
  }
}

.mt-8 {
  margin-top: 8px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-52 {
  margin-bottom: 52px;
}

@media screen and (max-width: 992px) {
  .mt-t-32 {
    margin-top: 32px;
  }
}

@media screen and (max-width: 768px) {
  .mb-m-0 {
    margin-bottom: 0;
  }
  .mb-m-8 {
    margin-bottom: 8px;
  }
  .mb-m-20 {
    margin-bottom: 20px;
  }
  .mb-m-24 {
    margin-bottom: 24px;
  }
  .mt-m-0 {
    margin-top: 0;
  }
  .mt-m-12 {
    margin-top: 12px;
  }
  .mt-m-20 {
    margin-top: 20px;
  }
  .mt-m-60 {
    margin-top: 60px;
  }
}

.pb-16 {
  padding-bottom: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-60 {
  padding-top: 60px;
}

@media screen and (max-width: 768px) {
  .pt-m-16 {
    padding-top: 16px;
  }
}

.slider {
  position: relative;
  overflow: hidden;
}

.slider-pagination.swiper-pagination-bullets {
  position: absolute;
  bottom: 54px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1152px;
  z-index: 10;
}

.slider-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0 0 26px;
  display: block;
  transition: 0.3s;
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 1;
}

.slider-pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #FFC448;
}

.slider__arrow {
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 12px;
  z-index: 5;
  color: #008BFF;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: 1px solid #008BFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
}

.slider__arrow svg {
  width: 20px;
  height: 20px;
}

.slider__arrow:hover {
  opacity: 0.5;
}

.slider__arrow_next {
  right: 0;
}

.slider__arrow_prev {
  left: 0;
}

.slider__arrow_prev svg {
  transform: rotate(180deg);
}

.slider__arrow.swiper-button-disabled {
  opacity: 0;
}

.slider__nav {
  display: flex;
}

.slider__nav_center {
  justify-content: center;
}

.slider__nav .slider__arrow {
  position: static;
}

.slider__nav .slider__arrow:not(:last-child) {
  margin-right: 24px;
}

@media screen and (max-width: 768px) {
  .slider__nav .slider__arrow:not(:last-child) {
    margin-right: 12px;
  }
}

.form__label {
  display: block;
  font-weight: 400;
  font-size: 15px;
  opacity: 0.9;
  line-height: 1.8;
}

.form label.error {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .form__label {
    font-size: 13px;
  }
}

.input {
  height: 42px;
  padding: 0 24px;
  border: 1px solid #535563;
  background: #E2E2E2;
  border-radius: 2px;
  font-size: 13px;
  color: #535563;
  font-family: 'Rubik', sans-serif;
  transition: 0.3s;
  display: block;
  width: 100%;
}

.input::-webkit-input-placeholder {
  opacity: 0.3;
}

.input::-moz-placeholder {
  opacity: 0.3;
}

.input:-moz-placeholder {
  opacity: 0.3;
}

.input:-ms-input-placeholder {
  opacity: 0.3;
}

.input::placeholder {
  opacity: 0.3;
}

.input_white {
  border-color: #ffffff;
  background: #ffffff;
}

.input:focus {
  border-color: #007BBC;
}

.input.error {
  border-color: red;
}

.input_text {
  height: 165px;
  padding: 14px 24px;
  resize: none;
}

@media screen and (max-width: 768px) {
  .input {
    font-size: 12px;
  }
  .input_text {
    height: 103px;
  }
}

.select .select2-container--default .select2-selection--single {
  border: 1px solid #ffffff;
  border-radius: 2px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 24px 0 32px;
  background: #ffffff;
}

.select .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: rgba(83, 85, 99, 0.6);
  font-size: 18px;
}

.select .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  flex-grow: 1;
  min-width: 0;
}

.select .select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select .select2-container--default .select2-selection--single .select2-selection__arrow {
  position: static;
  width: 24px;
  height: 24px;
  background-image: url("img/select-arrow.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  transition: 0.3s ease-in-out;
}

.select .select2-container--default.select2-container--open .select2-selection__arrow {
  transform: rotate(180deg);
}

.select__dropdown {
  font-size: 18px;
  position: relative;
}

.select__dropdown .select2-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.06);
  overflow: hidden;
  background: #ffffff;
  color: rgba(83, 85, 99, 0.6);
  padding: 12px 0;
}

.select__dropdown .select2-dropdown.select2-dropdown--below {
  border-radius: 0;
}

.select__dropdown .select2-dropdown.select2-dropdown--above {
  border-radius: 0;
}

.select__dropdown .select2-results__option {
  padding: 12px 50px 12px 32px;
  transition: 0.3s ease-in-out;
  position: relative;
}

.select__dropdown .select2-results__option::after {
  width: 24px;
  height: 24px;
  background-image: url("img/checked.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  transition: 0.3s ease-in-out;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  opacity: 0;
}

.select__dropdown .select2-container--default .select2-results__option--selected {
  background-color: #ffffff;
  color: #56AEF8;
}

.select__dropdown .select2-container--default .select2-results__option--selected::after {
  opacity: 1;
}

.select__dropdown .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #ffffff;
  color: #56AEF8;
}

@media screen and (max-width: 768px) {
  .select .select2-container--default .select2-selection--single {
    height: 36px;
    padding: 0 24px;
  }
  .select .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 14px;
  }
  .select .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
  }
  .select .select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 20px;
    height: 20px;
  }
  .select__dropdown {
    font-size: 14px;
    position: relative;
  }
  .select__dropdown .select2-dropdown {
    padding: 8px 0;
  }
  .select__dropdown .select2-results__option {
    padding: 8px 50px 8px 24px;
    transition: 0.3s ease-in-out;
    position: relative;
  }
  .select__dropdown .select2-results__option::after {
    width: 14px;
    height: 14px;
    right: 20px;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 1005;
  transition: 0.3s ease-in-out;
  padding: 32px 0 8px;
}

.header.is-up {
  background-color: #ffffff;
}

.header__logo {
  color: #969391;
}

.header-menu__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-menu__item:not(:first-child) {
  padding-left: 56px;
}

.header-menu__link {
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(105, 105, 105, 0.6);
}

.header-menu__link:hover, .header-menu__link.is-active {
  color: #56AEF8;
}

@media screen and (min-width: 993px) {
  .header.is-up {
    padding-top: 8px;
  }
}

@media screen and (max-width: 1200px) {
  .header-menu__item:not(:first-child) {
    padding-left: 24px;
  }
}

@media screen and (max-width: 992px) {
  .header {
    background: #F3F7FB;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
    padding: 12px 0;
    color: #535563;
    height: 64px;
  }
  .header__logo {
    width: 38px;
    height: 39px;
    overflow: hidden;
  }
  .header__logo svg {
    width: 140px;
    height: 39px;
    max-width: none;
  }
  .header-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(179.97deg, #54B0FC 0.03%, #CBE7FF 99.97%);
    display: none;
    overflow-y: auto;
    padding: 42px 22px 60px;
    z-index: 10;
  }
  .header-menu__wrapper {
    display: block;
    text-align: center;
  }
  .header-menu__logo {
    margin-bottom: 12px;
  }
  .header-menu__logo .logo {
    margin: 0 auto;
  }
  .header-menu .burger {
    position: absolute;
    top: 32px;
    left: 24px;
  }
  .header-menu .burger__line {
    background: #FFC448;
  }
  .header-menu__item {
    padding: 24px 0;
  }
  .header-menu__item:not(:first-child) {
    padding-left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
  .header-menu__link {
    color: #ffffff;
  }
  .header-menu__link:hover, .header-menu__link.is-active {
    color: #ffffff;
  }
}

@media screen and (max-width: 576px) {
  .header {
    height: 54px;
    padding: 6px 0;
  }
}

.main-slider__item {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-top: 128px;
}

.main-slider__content {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 128px);
  padding: 36px 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .main-slider__item {
    padding-top: 64px;
  }
  .main-slider__content {
    padding: 36px 0 54px;
    width: 100%;
    min-height: calc(100vh - 64px);
  }
}

@media screen and (max-width: 576px) {
  .main-slider__item {
    padding-top: 54px;
  }
  .main-slider__content {
    min-height: calc(100vh - 54px);
  }
}

.schedule {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 60px 0;
}

.schedule__image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
}

.schedule__image img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.schedule-slider {
  padding: 0 76px;
  margin: 0 -76px 15px;
  height: 78px;
}

.schedule-slider::after {
  content: '';
  background: #ffffff;
  width: 76px;
  top: 0;
  bottom: 0;
  z-index: 2;
  left: 0;
  position: absolute;
}

.schedule-slider::before {
  content: '';
  background: #ffffff;
  width: 76px;
  top: 0;
  bottom: 0;
  z-index: 2;
  right: 0;
  position: absolute;
}

.schedule-slider__date {
  font-weight: 500;
  font-size: 20px;
  color: #535563;
  opacity: 0.4;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;
  padding-bottom: 16px;
  position: relative;
  text-align: center;
}

.schedule-slider__date::after {
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 4px;
  background: #FFC448;
  width: 0;
  transition: 0.3s ease-in-out;
  content: '';
}

.schedule-slider__date:hover {
  opacity: 0.8;
}

.schedule-slider__date:hover::after {
  width: 100%;
}

.schedule-slider__item {
  border-bottom: 4px solid rgba(83, 85, 99, 0.1);
}

.schedule-slider__item.swiper-slide-active .schedule-slider__date {
  opacity: 0.8;
}

.schedule-slider__item.swiper-slide-active .schedule-slider__date::after {
  width: 100%;
}

.schedule-text-slider {
  margin-bottom: 40px;
  min-height: 136px;
}

.schedule__content {
  padding-left: 100px;
}

@media screen and (max-width: 1200px) {
  .schedule__content {
    padding-left: 40px;
  }
  .schedule-slider {
    height: auto;
    padding: 0 0;
    margin: 0 0 20px;
  }
  .schedule-slider::after {
    display: none;
  }
  .schedule-slider::before {
    display: none;
  }
  .schedule-slider .slider__arrow {
    display: none;
  }
  .schedule-text-slider {
    min-height: 0;
  }
}

@media screen and (max-width: 992px) {
  .schedule {
    background: #F3F7FB;
    min-height: 0;
    padding-bottom: 0;
    display: block;
  }
  .schedule__content {
    padding-left: 0;
  }
  .schedule__image {
    position: static;
    width: 100%;
  }
  .schedule__image img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .schedule-slider__date {
    font-size: 14px;
  }
}

.drugs-group {
  display: flex;
  align-items: center;
  margin-right: 68px;
}

.drugs-group__icon {
  width: 120px;
  min-width: 120px;
  margin-right: 32px;
}

.drugs-group__icon img {
  display: block;
}

.drugs-group__text {
  max-width: 210px;
}

@media screen and (max-width: 1200px) {
  .drugs-group {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .drugs-group__icon {
    width: 100px;
    min-width: 100px;
    margin-right: 16px;
  }
}

.article {
  display: block;
  height: 100%;
  position: relative;
}

.article__image {
  height: 100%;
  position: relative;
  z-index: 1;
}

.article__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.article__layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px;
  background-image: url("img/image-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 3;
}

.article__title {
  display: block;
  transition: 0.3s ease-in-out;
}

.article__text {
  display: block;
  transition: 0.3s ease-in-out;
}

.article:hover .article__title,
.article:hover .article__text {
  transform: translateY(-10px);
}

@media screen and (max-width: 992px) {
  .article {
    height: 200px;
  }
  .article__layout {
    padding: 24px;
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .article {
    height: 94px;
  }
  .article__text {
    display: none;
  }
}

.main-tabs {
  display: flex;
}

.main-tabs__menu {
  width: 33.3333%;
  min-width: 33.3333%;
  padding-top: 162px;
}

.main-tabs__item {
  max-width: 216px;
  margin: 0 auto 20px;
  border-bottom: 1px solid transparent;
  padding-bottom: 4px;
  transition: 0.3s ease-in-out;
}

.main-tabs__item.is-active {
  border-color: #FFC448;
}

.main-tabs__item.is-active .main-tabs__link {
  opacity: 1;
  text-decoration: none;
}

.main-tabs__link {
  display: inline-block;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  color: #202126;
  opacity: 0.5;
  text-decoration: underline;
  font-weight: 500;
  font-size: 24px;
}

.main-tabs__link:hover {
  opacity: 1;
  text-decoration: none;
}

.main-tabs__content {
  flex-grow: 1;
  min-width: 0;
  position: relative;
  max-width: 632px;
}

.main-tabs__image {
  position: absolute;
  bottom: -50px;
  left: 0;
  transform: translateX(-50%);
  max-width: 80%;
  pointer-events: none;
}

@media screen and (max-width: 992px) {
  .main-tabs__menu {
    padding-top: 60px;
  }
}

@media screen and (max-width: 768px) {
  .main-tabs__menu {
    min-width: 120px;
    padding-right: 16px;
  }
  .main-tabs__link {
    font-size: 12px;
  }
  .main-tabs__content {
    margin-right: -16px;
  }
}

.download {
  position: relative;
  background: #4CACFC;
}

.download__content {
  height: 778px;
  padding: 78px 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(149.22deg, #4CACFC 0%, #87C7FD 59.35%, rgba(255, 255, 255, 0.35) 96.19%);
}

.download__text {
  max-width: 190px;
}

.download__button {
  display: block;
  transition: 0.3s ease-in-out;
  background: #FFC448;
  border: 1px solid #FFC448;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  color: #ffffff;
}

.download__button svg {
  width: 30px;
  height: 30px;
}

.download__button:hover {
  color: #FFC448;
  background: #ffffff;
}

.download__top {
  position: relative;
}

.download__title {
  transition: 0.3s ease-in-out;
}

.download-search__button {
  width: 26px;
  height: 26px;
  min-width: 26px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.download-search__form {
  width: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ffffff;
}

.download-search__form.is-open {
  width: 100%;
}

.download-search__wrapper {
  flex-grow: 1;
  padding: 0 24px;
  min-width: 0;
}

.download-search__input {
  height: 30px;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  font-weight: 300;
  font-size: 20px;
  color: #ffffff;
}

.download-search__input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.6;
}

.download-search__input::-moz-placeholder {
  color: #ffffff;
  opacity: 0.6;
}

.download-search__input:-moz-placeholder {
  color: #ffffff;
  opacity: 0.6;
}

.download-search__input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.6;
}

.download-search__input::placeholder {
  color: #ffffff;
  opacity: 0.6;
}

.download__link {
  display: inline-block;
  cursor: pointer;
}

.download__link:hover {
  text-decoration: underline;
}

.download__back {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  margin-right: 12px;
}

.download-group {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.download-group__button {
  margin-top: 36px;
}

.download-group__button .download__button {
  margin-right: auto;
}

@media screen and (max-width: 992px) {
  .download__content {
    padding: 24px 32px 24px 40px;
  }
  .download__back {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
}

@media screen and (max-width: 768px) {
  .download__content {
    height: 442px;
    padding-right: 16px;
    padding-left: 32px;
  }
  .download__text {
    max-width: 144px;
  }
  .download__button {
    width: 56px;
    height: 56px;
  }
  .download__button svg {
    width: 20px;
    height: 20px;
  }
  .download-search__button {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
  .download-search__form {
    padding: 4px 0;
  }
  .download-search__wrapper {
    padding: 0 12px;
  }
  .download-search__input {
    height: 24px;
    font-size: 12px;
  }
  .download__back {
    width: 18px;
    height: 18px;
  }
  .download-group {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .download-group__button {
    margin: 0 0 0 16px;
  }
}

.main-contacts {
  min-height: 555px;
  position: relative;
}

.main-contacts::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: linear-gradient(179.26deg, rgba(255, 255, 255, 0.2) 0.64%, rgba(255, 255, 255, 0) 15.76%), linear-gradient(328.77deg, #F3F5F9 0%, #FFFFFF 101.51%);
  opacity: 0.56;
  z-index: 1;
}

.main-contacts::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-image: url("img/contacts-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 100%;
  z-index: 2;
  opacity: 0.56;
}

.main-contacts .content {
  position: relative;
  z-index: 3;
}

.main-contacts__top {
  margin-bottom: 100px;
}

.main-contacts__line {
  display: flex;
}

.main-contacts__name {
  width: 136px;
  min-width: 136px;
  padding-right: 12px;
}

@media screen and (max-width: 768px) {
  .main-contacts {
    min-height: 0;
  }
  .main-contacts__top {
    margin-bottom: 35px;
  }
  .main-contacts__name {
    width: 88px;
    min-width: 88px;
  }
}

.footer {
  padding: 90px 0 48px;
}

.footer-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer-menu__item:not(:first-child) {
  padding-left: 100px;
}

.footer-menu__link {
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
  color: #535563;
}

.footer-menu__link:hover {
  color: #56AEF8;
}

.footer-line {
  background: #535563;
  opacity: 0.1;
  height: 2px;
}

.footer-copy {
  font-size: 10px;
  letter-spacing: 0.215385px;
  color: #1F2432;
  opacity: 0.4;
}

@media screen and (max-width: 992px) {
  .footer-menu__item:not(:first-child) {
    padding-left: 40px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 24px 0;
    text-align: center;
  }
  .footer__logo {
    margin: 0 auto;
  }
  .footer-menu {
    display: block;
    padding-top: 10px;
  }
  .footer-menu__item {
    padding-top: 20px;
  }
  .footer-menu__item:not(:first-child) {
    padding-left: 0;
  }
  .footer-menu__link {
    font-size: 12px;
  }
  .footer-line {
    display: none;
  }
  .footer-copy {
    margin-top: 8px;
  }
}

.soc {
  display: flex;
  margin: 0 -15px;
}

.soc__item {
  padding: 0 15px;
}

.soc__link {
  display: block;
  width: 24px;
  min-width: 24px;
  height: 24px;
}

@media screen and (max-width: 768px) {
  .soc {
    justify-content: center;
  }
}

.burger {
  cursor: pointer;
}

.burger__line {
  transition: 0.3s ease-in-out;
  width: 22px;
  height: 2px;
  background: #56AEF8;
  margin: 5px 0;
  position: relative;
}

.burger__line:first-child {
  top: 0;
}

.burger__line:nth-child(2) {
  opacity: 1;
}

.burger__line:nth-child(3) {
  top: 0;
}

.burger:hover {
  opacity: 0.7;
}

.burger.is-active .burger__line:first-child {
  top: 7px;
  transform: rotate(45deg);
}

.burger.is-active .burger__line:nth-child(2) {
  opacity: 0;
}

.burger.is-active .burger__line:nth-child(3) {
  top: -7px;
  transform: rotate(-45deg);
}

.lang {
  position: relative;
  color: #535563;
  padding: 8px 12px;
  border-radius: 2px 2px 0 0;
  transition: 0.3s ease-in-out;
  margin-right: -12px;
}

.lang__button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lang__image {
  width: 20px;
  min-width: 20px;
  margin-right: 8px;
}

.lang__image img {
  display: block;
}

.lang__arrow {
  transition: 0.3s ease-in-out;
  transform: rotate(90deg);
  width: 28px;
  min-width: 28px;
  height: 16px;
}

.lang__dropdown {
  background: #F3F7FB;
  border-radius: 0 0 2px 2px;
  padding: 8px 12px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
}

.lang__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.lang__item:not(:last-child) {
  margin-bottom: 8px;
}

.lang__item:hover {
  color: #56AEF8;
}

.lang.is-active {
  color: #535563;
  background: #F3F7FB;
}

.lang.is-active .lang__arrow {
  transform: rotate(-90deg);
}

@media screen and (max-width: 992px) {
  .lang {
    margin: 0 0 0 -12px;
  }
}

.search {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.search__wrapper {
  flex-grow: 1;
  min-width: 0;
  padding: 0 12px;
}

.search__input {
  height: 42px;
  background: transparent;
  border: 1px solid rgba(160, 162, 163, 0.6);
  border-radius: 2px;
  font-size: 13px;
  padding: 0 48px 0 16px;
  font-family: inherit;
  transition: 0.3s;
  display: block;
  width: 100%;
  color: #535563;
}

.search__input::-webkit-input-placeholder {
  color: #535563;
  opacity: 0.6;
}

.search__input::-moz-placeholder {
  color: #535563;
  opacity: 0.6;
}

.search__input:-moz-placeholder {
  color: #535563;
  opacity: 0.6;
}

.search__input:-ms-input-placeholder {
  color: #535563;
  opacity: 0.6;
}

.search__input::placeholder {
  color: #535563;
  opacity: 0.6;
}

.search__input:focus {
  border-color: #535563;
}

.search__button {
  background: transparent;
  border: none;
  width: 18px;
  min-width: 18px;
  height: 18px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  color: #535563;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.search__button:hover {
  opacity: 1;
}

.search-block {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #F3F7FB;
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
  display: none;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
}

.search-block.is-visible {
  display: block;
}

.search-block.is-show {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.search-form {
  position: relative;
}

.search-results {
  padding: 12px 0 32px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.search-results__item {
  margin-top: 20px;
  display: flex;
}

.search-results__image {
  width: 52px;
  min-width: 52px;
  margin-right: 20px;
}

@media screen and (max-width: 992px) {
  .search__input {
    border: none;
    border-bottom: 1px solid rgba(160, 162, 163, 0.6);
    padding: 0 8px;
    border-radius: 0;
  }
  .search__button {
    position: static;
    transform: translate(0, 0);
  }
}

.error-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.error-page__logo {
  margin-bottom: 95px;
}

.error-page__text {
  margin-bottom: 50px;
}

@media screen and (max-width: 576px) {
  .error-page__text {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
  .error-page__text .button {
    width: 100%;
  }
  .error-page__image {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.popup.mfp-bg {
  background: rgba(0, 0, 0, 0.69);
  opacity: 1;
}

.popup__close {
  width: 26px;
  height: 26px;
  cursor: pointer;
  transition: 0.3s ease-out;
  z-index: 50;
  position: absolute;
  top: 40px;
  right: 40px;
  color: #ffffff;
}

.popup__close:hover {
  opacity: 0.7;
}

.popup__wrapper {
  padding: 16px;
  margin: 0 auto;
  max-width: 976px;
}

.popup__block {
  background: #3D3D3D;
  border-radius: 2px;
  position: relative;
  color: #ffffff;
  padding: 54px 68px;
}

.popup .mfp-close {
  display: none;
}

.popup__text {
  opacity: 0.6;
}

@media screen and (max-width: 992px) {
  .popup__block {
    padding: 40px 32px;
  }
  .popup__close {
    right: 32px;
  }
}

@media screen and (max-width: 768px) {
  .popup__close {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 576px) {
  .popup__block {
    padding: 32px 20px;
  }
  .popup__close {
    right: 20px;
    top: 24px;
  }
}

.breadcrumbs {
  padding-bottom: 20px;
}

.breadcrumbs__link {
  display: inline-flex;
  align-items: center;
  transition: 0;
}

.breadcrumbs a.breadcrumbs__link:hover .breadcrumbs__text {
  color: #56AEF8;
  opacity: 1;
}

.breadcrumbs__icon {
  display: block;
  width: 14px;
  min-width: 14px;
  height: 14px;
  margin-right: 5px;
  color: #56AEF8;
}

.breadcrumbs__text {
  display: block;
  opacity: 0.5;
  color: #535563;
  transition: 0.3s;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .breadcrumbs__text {
    font-size: 12px;
  }
}

.card {
  display: flex;
  height: 100%;
  flex-direction: column;
  min-height: 340px;
  color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.card_blue .card__bg {
  background: linear-gradient(229.65deg, rgba(255, 255, 255, 0) 70.3%, rgba(0, 193, 255, 0.8) 108.71%), linear-gradient(124.9deg, rgba(0, 193, 255, 0) 42.77%, #00C1FF 97.84%), linear-gradient(106.54deg, rgba(85, 214, 255, 0.3) 7.75%, #00C1FF 89.78%);
}

.card_blue .card__info {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #00C1FF 100%);
}

.card_green .card__bg {
  background: linear-gradient(229.65deg, rgba(255, 255, 255, 0) 70.3%, rgba(102, 194, 15, 0.8) 108.71%), linear-gradient(124.9deg, rgba(163, 210, 13, 0) 42.77%, #66C20F 97.84%), linear-gradient(106.54deg, rgba(163, 210, 13, 0.3) 7.75%, #66C20F 89.78%);
}

.card_green .card__info {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #66C20F 100%);
}

.card_yellow .card__bg {
  background: linear-gradient(229.65deg, rgba(255, 255, 255, 0) 70.3%, rgba(253, 201, 0, 0.8) 108.71%), linear-gradient(124.9deg, rgba(253, 201, 0, 0) 42.77%, #FDC900 97.84%), linear-gradient(106.54deg, rgba(253, 201, 0, 0.3) 7.75%, #FDC900 89.78%);
}

.card_yellow .card__info {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #FDC900 100%);
}

.card__bg {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.33;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.card__image {
  height: 234px;
  padding: 40px 40px 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 3;
}

.card__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.4s;
}

.card__info {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  padding: 24px 28px;
  position: relative;
  z-index: 3;
}

.card__row {
  display: flex;
  align-items: center;
  width: 100%;
}

.card__name {
  flex-grow: 1;
  min-width: 0;
}

.card__arrow {
  width: 40px;
  height: 40px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  margin-left: 16px;
}

.card__arrow svg {
  width: 20px;
  height: 20px;
}

.card:hover .card__arrow {
  opacity: 1;
}

.card:hover .card__image img {
  transform: scale(1.2) rotate(8deg);
}

@media screen and (max-width: 992px) {
  .card__image {
    padding: 20px 20px 0;
  }
  .card__info {
    padding: 12px 28px;
  }
}

@media screen and (max-width: 768px) {
  .card {
    min-height: 192px;
  }
  .card__image {
    height: 144px;
  }
  .card__arrow {
    display: none;
  }
}

.product__view {
  max-width: 615px;
}

.product__image {
  position: relative;
  padding-top: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.product__image img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  width: auto;
}

.product__download {
  opacity: 0.8;
}

.product_blue .product__image {
  background-image: url("img/product-bg-blue.png");
}

.product_green .product__image {
  background-image: url("img/product-bg-green.png");
}

.product_yellow .product__image {
  background-image: url("img/product-bg-yellow.png");
}

@media screen and (max-width: 992px) {
  .product__view {
    margin: 0 auto;
    max-width: 75%;
  }
  .product__image img {
    max-height: 60%;
    max-width: 60%;
  }
}

.accordion-button {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.accordion-button__text {
  text-decoration: underline;
  padding-right: 16px;
}

.accordion-button__icon {
  margin-left: 16px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  box-shadow: 0px 2px 14px rgba(147, 205, 255, 0.31);
}

.accordion-button__icon::after, .accordion-button__icon::before {
  position: absolute;
  height: 2px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #56AEF8;
  content: '';
  transition: 0.3s;
}

.accordion-button__icon::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.accordion-button:hover .accordion-button__text {
  text-decoration: none;
}

.accordion-button.is-active .accordion-button__icon::after, .accordion-button.is-active .accordion-button__icon::before {
  background: #FFC448;
}

.accordion-button.is-active .accordion-button__icon::after {
  transform: translate(-50%, -50%) rotate(0);
}

@media screen and (min-width: 993px) {
  .main-page .header:not(.is-up) .header-menu__link {
    color: rgba(255, 255, 255, 0.6);
  }
  .main-page .header:not(.is-up) .header-menu__link:hover, .main-page .header:not(.is-up) .header-menu__link.is-active {
    color: #56AEF8;
  }
  .main-page .header:not(.is-up) .lang {
    color: #ffffff;
  }
  .main-page .header:not(.is-up) .lang.is-active {
    color: #535563;
  }
  .main-page .header:not(.is-up) .header__logo {
    color: #ffffff;
  }
  .main-page .header:not(.is-up) .search__input {
    border-color: rgba(255, 255, 255, 0.6);
    color: #ffffff;
  }
  .main-page .header:not(.is-up) .search__input::-webkit-input-placeholder {
    color: #ffffff;
  }
  .main-page .header:not(.is-up) .search__input::-moz-placeholder {
    color: #ffffff;
  }
  .main-page .header:not(.is-up) .search__input:-moz-placeholder {
    color: #ffffff;
  }
  .main-page .header:not(.is-up) .search__input:-ms-input-placeholder {
    color: #ffffff;
  }
  .main-page .header:not(.is-up) .search__input::placeholder {
    color: #ffffff;
  }
  .main-page .header:not(.is-up) .search__input:focus {
    border-color: #ffffff;
  }
  .main-page .header:not(.is-up) .search__button {
    color: #ffffff;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__item {
  padding: 0 12px;
}

.pagination__arrow {
  cursor: pointer;
  width: 16px;
  height: 16px;
  min-width: 16px;
}

.pagination__arrow_prev svg {
  transform: rotate(180deg);
}

.pagination__arrow.is-disabled {
  opacity: 0.2;
}

.pagination__link {
  font-size: 20px;
  cursor: pointer;
  opacity: 0.4;
  transition: 0.3s ease-in-out;
}

.pagination__link:hover, .pagination__link.is-active {
  opacity: 1;
}

.about-slider .swiper-slide {
  height: auto;
  overflow: hidden;
}

.about-slider__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.about-slider__image::after {
  content: '';
  position: absolute;
  background: linear-gradient(336.31deg, #FFC448 -23.55%, rgba(255, 255, 255, 0) 84.72%, rgba(255, 255, 255, 0) 84.72%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.about-slider__image img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about-slider__wrapper {
  height: 100%;
  min-height: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 5;
  max-width: 256px;
}

.about-slider__wrapper::before {
  content: '';
  position: absolute;
  background: #F3F7FB;
  top: 0;
  bottom: 0;
  right: 36px;
  width: 100vw;
}

.about-slider__wrapper::after {
  content: '';
  position: absolute;
  background: #F3F7FB;
  transform-origin: 100% 0;
  width: 500px;
  height: 200%;
  top: 0;
  right: 35px;
  transform: rotate(-38deg);
}

.about-slider__content {
  position: relative;
  z-index: 10;
}

.about-slider__date {
  font-weight: 500;
  font-size: 96px;
  line-height: 1.2;
}

@media screen and (max-width: 992px) {
  .about-slider__wrapper::after {
    transform: rotate(-24deg);
  }
}

@media screen and (max-width: 768px) {
  .about-slider__wrapper {
    min-height: 268px;
    max-width: 130px;
  }
  .about-slider__wrapper::before {
    right: 0;
  }
  .about-slider__wrapper::after {
    right: 0;
  }
  .about-slider__date {
    font-size: 46px;
  }
}

.certificates-slider__content {
  max-width: 368px;
  padding-bottom: 1px;
}

@media screen and (max-width: 768px) {
  .certificates-slider__content {
    max-width: none;
    display: flex;
    flex-direction: column;
  }
  .certificates-slider__content .slider__nav {
    order: -1;
    margin: 16px 0 24px;
    justify-content: center;
  }
}

.group__image {
  display: flex;
}

.group__image_right {
  margin-left: -10px;
  justify-content: flex-start;
}

.group__image_left {
  margin-right: -10px;
  justify-content: flex-end;
}

.group__image img {
  display: block;
  width: 50vw;
  min-width: 50vw;
}

.group__content {
  height: 100%;
  width: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 922px) {
  .group__content {
    width: 100%;
  }
  .group__image {
    display: flex;
  }
  .group__image_full {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .group__image {
    display: block;
  }
  .group__image_half {
    margin: 0;
    height: 100%;
  }
  .group__image_half img {
    width: 100%;
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .group__image_full {
    margin-left: -16px;
    margin-right: -16px;
    position: relative;
  }
  .group__image_full::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #F3F7FB 0%, rgba(243, 247, 251, 0.94) 10.73%, rgba(243, 247, 251, 0) 100%);
    height: 60%;
    content: '';
  }
  .group__image_full img {
    width: 100%;
    min-width: 100%;
  }
}

.gallery-slider .swiper-slide {
  transition: 0.3s ease-in-out;
  padding: 24px 0;
  height: auto;
}

.gallery-slider .swiper-slide.swiper-slide-active {
  padding: 0;
}

.gallery-slider .swiper-slide.swiper-slide-active .gallery-slider__item::after {
  opacity: 0;
}

.gallery-slider__item {
  height: 100%;
  position: relative;
}

.gallery-slider__item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gallery-slider__item::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(24, 24, 24, 0.7);
  content: '';
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .gallery-slider .swiper-slide {
    padding: 8px 0;
  }
}

.contacts-form {
  max-width: 528px;
  margin: 0 auto;
}

.delivery-text {
  max-width: 844px;
}

.delivery-map img {
  width: 100%;
}

.delivery-roll__button {
  max-width: 686px;
  margin: 0 auto;
}

.delivery-roll__nav {
  cursor: pointer;
}

.delivery-roll__nav.is-active .delivery-roll__arrow {
  transform: rotate(90deg);
}

.delivery-roll__arrow {
  width: 22px;
  height: 22px;
  transform: rotate(-90deg);
  transition: 0.3s ease-in-out;
}

.delivery-list {
  padding-top: 18px;
}

.delivery-list__point {
  position: relative;
  padding-bottom: 8px;
  padding-left: 24px;
}

.delivery-list__point::after {
  height: 1px;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 158px;
  position: absolute;
  content: '';
  background: #FFC448;
}

.delivery-list__point::before {
  height: 3px;
  width: 3px;
  top: 10px;
  left: 8px;
  background: #535563;
  border-radius: 50%;
  position: absolute;
  content: '';
}

.delivery-promo {
  background-image: url("img/delivery.png");
  background-size: cover;
  background-position: 0 50%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.delivery-promo__wrapper {
  min-height: 640px;
  position: relative;
  max-width: 250px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.delivery-promo__wrapper::before {
  content: '';
  position: absolute;
  background: #F3F7FB;
  top: 0;
  bottom: 0;
  left: 38px;
  width: 100vw;
}

.delivery-promo__wrapper::after {
  content: '';
  position: absolute;
  background: #F3F7FB;
  transform-origin: 0 0;
  width: 500px;
  height: 200%;
  top: 0;
  left: 35px;
  transform: rotate(38deg);
}

.delivery-promo__content {
  position: relative;
  z-index: 5;
}

@media screen and (max-width: 992px) {
  .delivery-promo__wrapper::after {
    transform: rotate(24deg);
  }
}

@media screen and (max-width: 768px) {
  .delivery-list {
    padding-top: 8px;
  }
  .delivery-list__point {
    padding-left: 16px;
  }
  .delivery-list__point::before {
    top: 8px;
    left: 6px;
  }
  .delivery-promo__wrapper {
    min-height: 268px;
    max-width: 135px;
    align-items: stretch;
  }
  .delivery-promo__wrapper::before {
    left: 0;
  }
  .delivery-promo__wrapper::after {
    left: 0;
  }
  .delivery-promo__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
