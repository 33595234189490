.slider {
	position: relative;
	overflow: hidden;

	&-pagination {
		&.swiper-pagination-bullets {
			position: absolute;
			bottom: 54px;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin: 0 auto;
			padding: 0 16px;
			max-width: 1152px;
			z-index: 10;

			.swiper-pagination-bullet {
				margin: 0 0 0 26px;
				display: block;
				transition: 0.3s;
				width: 8px;
				height: 8px;
				background: #FFFFFF;
				border-radius: 50%;
				opacity: 1;

				&.swiper-pagination-bullet-active {
					background: #FFC448;
				}
			}
		}
	}

	&__arrow {
		width: 48px;
		height: 48px;
		position: absolute;
		bottom: 12px;
		z-index: 5;
		color: #008BFF;
		transition: 0.3s ease-in-out;
		cursor: pointer;
		border: 1px solid #008BFF;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #FFFFFF;

		svg {
			width: 20px;
			height: 20px;
		}

		&:hover {
			opacity: 0.5;
		}

		&_next {
			right: 0;
		}

		&_prev {
			left: 0;

			svg {
				transform: rotate(180deg);
			}

		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}

	&__nav {
		display: flex;

		&_center {
			justify-content: center;
		}

		.slider__arrow {
			position: static;

			&:not(:last-child) {
				margin-right: 24px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		&__nav {
			.slider__arrow {
				&:not(:last-child) {
					margin-right: 12px;
				}
			}
		}
	}
}