.drugs-group {
    display: flex;
    align-items: center;
    margin-right: 68px;

    &__icon {
        width: 120px;
        min-width: 120px;
        margin-right: 32px;

        img {
            display: block;
        }
    }

    &__text {
        max-width: 210px;
    }

    @media screen and (max-width: 1200px) {
        margin-right: 0;
    }

    @media screen and (max-width: 768px) {
        &__icon {
            width: 100px;
            min-width: 100px;
            margin-right: 16px;
        }
    }
}