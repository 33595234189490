.accordion {
    &-button {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;

        &__text {
            text-decoration: underline;
            padding-right: 16px;
        }

        &__icon {
            margin-left: 16px;
            width: 32px;
            min-width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #ffffff;
            position: relative;
            box-shadow: 0px 2px 14px rgba(147, 205, 255, 0.31);

            &::after,
            &::before {
                position: absolute;
                height: 2px;
                width: 20px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #56AEF8;
                content: '';
                transition: 0.3s;
            }

            &::after {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        &:hover {
            .accordion-button__text {
                text-decoration: none;
            }
        }

        &.is-active {
            .accordion-button__icon {

                &::after,
                &::before {
                    background: #FFC448;
                }

                &::after {
                    transform: translate(-50%, -50%) rotate(0);
                }
            }
        }
    }
}