.product {

    &__view {
        max-width: 615px;
    }

    &__image {
        position: relative;
        padding-top: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 100%;

        img {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 100%;
            width: auto;
        }
    }

    &__download {
        opacity: 0.8;
    }

    &_blue {
        .product__image {
            background-image: url('img/product-bg-blue.png');
        }
    }

    &_green {
        .product__image {
            background-image: url('img/product-bg-green.png');
        }
    }

    &_yellow {
        .product__image {
            background-image: url('img/product-bg-yellow.png');
        }
    }


    @media screen and (max-width: 992px) {
        &__view {
            margin: 0 auto;
            max-width: 75%;
        }

        &__image {

            img {
                max-height: 60%;
                max-width: 60%;
            }
        }
    }
}