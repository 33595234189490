.breadcrumbs {
    padding-bottom: 20px;

    &__link {
        display: inline-flex;
        align-items: center;
        transition: 0;
    }

    a.breadcrumbs__link {
        &:hover {
            .breadcrumbs__text {
                color: #56AEF8;
                opacity: 1;
            }
        }
    }

    &__icon {
        display: block;
        width: 14px;
        min-width: 14px;
        height: 14px;
        margin-right: 5px;
        color: #56AEF8;
    }

    &__text {
        display: block;
        opacity: 0.5;
        color: #535563;
        transition: 0.3s;
        font-size: 14px;
    }

    @media screen and (max-width: 768px) {
        &__text {
            font-size: 12px;
        }
    }
}