.about-slider {
    .swiper-slide {
        height: auto;
        overflow: hidden;
    }

    &__image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            background: linear-gradient(336.31deg, #FFC448 -23.55%, rgba(255, 255, 255, 0) 84.72%, rgba(255, 255, 255, 0) 84.72%);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__wrapper {
        height: 100%;
        min-height: 640px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 5;
        max-width: 256px;

        &::before {
            content: '';
            position: absolute;
            background: #F3F7FB;
            top: 0;
            bottom: 0;
            right: 36px;
            width: 100vw;
        }

        &::after {
            content: '';
            position: absolute;
            background: #F3F7FB;
            transform-origin: 100% 0;
            width: 500px;
            height: 200%;
            top: 0;
            right: 35px;
            transform: rotate(-38deg);
        }
    }

    &__content {
        position: relative;
        z-index: 10;
    }

    &__date {
        font-weight: 500;
        font-size: 96px;
        line-height: 1.2;
    }

    @media screen and (max-width: 992px) {
        &__wrapper {
            &::after {
                transform: rotate(-24deg);
            }
        }
    }

    @media screen and (max-width: 768px) {
        &__wrapper {
            min-height: 268px;
            max-width: 130px;

            &::before {
                right: 0;
            }

            &::after {
                right: 0;
            }
        }

        &__date {
            font-size: 46px;
        }
    }
}