.main-slider {

    &__item {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        padding-top: 128px;
    }

    &__content {
        display: flex;
        align-items: center;
        min-height: calc(100vh - 128px);
        padding: 36px 0;
        width: 100%;
    }


    @media screen and (max-width: 768px) {

        &__item {
            padding-top: 64px;
        }

        &__content {
            padding: 36px 0 54px;
            width: 100%;
            min-height: calc(100vh - 64px);
        }
    }

    @media screen and (max-width: 576px) {
        &__item {
            padding-top: 54px;
        }

        &__content {
            min-height: calc(100vh - 54px);
        }
    }
}