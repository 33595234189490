.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 1005;
    transition: 0.3s ease-in-out;
    padding: 32px 0 8px;

    &.is-up {
        background-color: #ffffff;
    }

    &__logo {
        color: #969391;
    }

    &-menu {
        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &__item {
            &:not(:first-child) {
                padding-left: 56px;
            }
        }

        &__link {
            display: inline-block;
            font-weight: 500;
            text-transform: uppercase;
            color: rgba(105, 105, 105, 0.6);

            &:hover,
            &.is-active {
                color: #56AEF8;
            }
        }
    }

    @media screen and (min-width: 993px) {
        &.is-up {
            padding-top: 8px;
        }
    }

    @media screen and (max-width: 1200px) {
        &-menu {
            &__item {
                &:not(:first-child) {
                    padding-left: 24px;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        background: #F3F7FB;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
        padding: 12px 0;
        color: #535563;
        height: 64px;

        &__logo {
            width: 38px;
            height: 39px;
            overflow: hidden;

            svg {
                width: 140px;
                height: 39px;
                max-width: none;
            }
        }

        &-menu {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(179.97deg, #54B0FC 0.03%, #CBE7FF 99.97%);
            display: none;
            overflow-y: auto;
            padding: 42px 22px 60px;
            z-index: 10;

            &__wrapper {
                display: block;
                text-align: center;
            }

            &__logo {
                margin-bottom: 12px;

                .logo {
                    margin: 0 auto;
                }
            }

            .burger {
                position: absolute;
                top: 32px;
                left: 24px;

                &__line {
                    background: #FFC448;
                }
            }

            &__item {
                padding: 24px 0;

                &:not(:first-child) {
                    padding-left: 0;
                    border-top: 1px solid rgba(255, 255, 255, 0.4);
                }

            }

            &__link {
                color: #ffffff;

                &:hover,
                &.is-active {
                    color: #ffffff;
                }
            }
        }
    }

    @media screen and (max-width: 576px) {
        height: 54px;
        padding: 6px 0;
    }
}