@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

html {
	overflow-x: hidden;

	&.is-fixed {
		overflow: hidden;
	}
}

body {
	background: #ffffff;
	color: #535563;
	font-family: 'Rubik', sans-serif;
	line-height: 1.5;
	font-size: 16px;
	overflow-x: hidden;
}

.wrap-all {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}

.wrap-content {
	flex-grow: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.logo {
	display: block;
	width: 110px;
	height: 85px;
	transition: 0.3s ease-in-out;

	img {
		display: block;
	}
}

.content {
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;
	max-width: 1152px;
}

.hidden {
	display: none;
}

.t-hidden {
	@media screen and (max-width: 992px) {
		display: none;
	}
}

.t-show {
	display: none;

	@media screen and (max-width: 992px) {
		display: block;
	}
}

.m-hidden {
	@media screen and (max-width: 768px) {
		display: none;
	}
}

.m-show {
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
	}
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

@media screen and (max-width: 992px) {
	.text-t-center {
		text-align: center;
	}
}

@media screen and (max-width: 768px) {
	.text-m-center {
		text-align: center;
	}
}


.text-white {
	color: #ffffff;
}

.text-white-opacity {
	color: rgba(255, 255, 255, 0.8);
}

.text-yellow {
	color: #FFC448;
}

.text-blue {
	color: #56AEF8;
}

.text-green {
	color: #66C20F;
}

.text-dark-opacity {
	color: rgba(83, 85, 99, 0.8);
}

.text-grey-opacity {
	color: rgba(147, 150, 159, 0.9);
}

.uppercase {
	text-transform: uppercase;
}

.fw-medium {
	font-weight: 500;
}

.fw-light {
	font-weight: 300;
}

svg {
	display: block;
	width: 100%;
	max-width: 100%;
	height: 100%;
	color: inherit;
}

.section {
	&_pt {
		padding-top: 90px;
	}

	&_pb {
		padding-bottom: 90px;
	}

	&_bg {
		background: #F3F7FB;
	}

	&_page {
		padding-top: 162px;
	}

	@media screen and (max-width: 992px) {
		&_page {
			padding-top: 84px;
		}
	}

	@media screen and (max-width: 768px) {
		&_pt {
			padding-top: 60px;
		}

		&_pb {
			padding-bottom: 60px;
		}

		&_m-pb {
			padding-bottom: 60px;
		}

		&_m-bg {
			background: #F3F7FB;
		}
	}

	@media screen and (max-width: 576px) {
		&_page {
			padding-top: 74px;
		}
	}
}

.underline {
	display: inline-block;
	text-decoration: underline;
}

.link {
	color: #56AEF8;

	&:hover {
		opacity: 0.8;
	}
}

.d-block {
	display: block;
}

.is-unvisible {
	opacity: 0;
}

.map {
	height: 555px;

	@media screen and (max-width: 768px) {
		height: 580px;
	}
}