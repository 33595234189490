.delivery {
    &-text {
        max-width: 844px;
    }

    &-map {
        img {
            width: 100%;
        }
    }

    &-roll {
        &__button {
            max-width: 686px;
            margin: 0 auto;
        }

        &__nav {
            cursor: pointer;

            &.is-active {
                .delivery-roll__arrow {
                    transform: rotate(90deg);
                }
            }
        }

        &__arrow {
            width: 22px;
            height: 22px;
            transform: rotate(-90deg);
            transition: 0.3s ease-in-out;
        }
    }

    &-list {
        padding-top: 18px;

        &__point {
            position: relative;
            padding-bottom: 8px;
            padding-left: 24px;

            &::after {
                height: 1px;
                bottom: 0;
                left: 0;
                width: 100%;
                max-width: 158px;
                position: absolute;
                content: '';
                background: #FFC448;
            }

            &::before {
                height: 3px;
                width: 3px;
                top: 10px;
                left: 8px;
                background: #535563;
                border-radius: 50%;
                position: absolute;
                content: '';
            }
        }
    }

    &-promo {
        background-image: url('img/delivery.png');
        background-size: cover;
        background-position: 0 50%;
        background-repeat: no-repeat;
        overflow: hidden;

        &__wrapper {
            min-height: 640px;
            position: relative;
            max-width: 250px;
            margin-left: auto;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                position: absolute;
                background: #F3F7FB;
                top: 0;
                bottom: 0;
                left: 38px;
                width: 100vw;
            }

            &::after {
                content: '';
                position: absolute;
                background: #F3F7FB;
                transform-origin: 0 0;
                width: 500px;
                height: 200%;
                top: 0;
                left: 35px;
                transform: rotate(38deg);
            }
        }

        &__content {
            position: relative;
            z-index: 5;
        }
    }

    @media screen and (max-width: 992px) {
        &-promo {
            &__wrapper {
                &::after {
                    transform: rotate(24deg);
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        &-list {
            padding-top: 8px;

            &__point {
                padding-left: 16px;

                &::before {
                    top: 8px;
                    left: 6px;
                }
            }
        }

        &-promo {
            &__wrapper {
                min-height: 268px;
                max-width: 135px;
                align-items: stretch;

                &::before {
                    left: 0;
                }

                &::after {
                    left: 0;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}