.lang {
    position: relative;
    color: #535563;
    padding: 8px 12px;
    border-radius: 2px 2px 0 0;
    transition: 0.3s ease-in-out;
    margin-right: -12px;

    &__button {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__image {
        width: 20px;
        min-width: 20px;
        margin-right: 8px;

        img {
            display: block;
        }
    }

    &__arrow {
        transition: 0.3s ease-in-out;
        transform: rotate(90deg);
        width: 28px;
        min-width: 28px;
        height: 16px;
    }

    &__dropdown {
        background: #F3F7FB;
        border-radius: 0 0 2px 2px;
        padding: 8px 12px;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: none;
    }

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &:hover {
            color: #56AEF8;
        }
    }

    &.is-active {
        color: #535563;
        background: #F3F7FB;

        .lang__arrow {
            transform: rotate(-90deg);
        }
    }

    @media screen and (max-width: 992px) {
        margin: 0 0 0 -12px;
    }
}