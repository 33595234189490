.popup {
    &.mfp-bg {
        background: rgba(0, 0, 0, 0.69);
        opacity: 1;
    }

    &__close {
        width: 26px;
        height: 26px;
        cursor: pointer;
        transition: 0.3s ease-out;
        z-index: 50;
        position: absolute;
        top: 40px;
        right: 40px;
        color: #ffffff;

        &:hover {
            opacity: 0.7;
        }
    }

    &__wrapper {
        padding: 16px;
        margin: 0 auto;
        max-width: 976px;
    }

    &__block {
        background: #3D3D3D;
        border-radius: 2px;
        position: relative;
        color: #ffffff;
        padding: 54px 68px;
    }

    .mfp-close {
        display: none;
    }

    &__text {
        opacity: 0.6;
    }

    @media screen and (max-width: 992px) {
        &__block {
            padding: 40px 32px;
        }

        &__close {
            right: 32px;
        }
    }

    @media screen and (max-width: 768px) {
        &__close {
            width: 16px;
            height: 16px;
        }
    }

    @media screen and (max-width: 576px) {
        &__block {
            padding: 32px 20px;
        }

        &__close {
            right: 20px;
            top: 24px;
        }
    }
}