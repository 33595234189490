.card {
    display: flex;
    height: 100%;
    flex-direction: column;
    min-height: 340px;
    color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    &_blue {
        .card__bg {
            background: linear-gradient(229.65deg, rgba(255, 255, 255, 0) 70.3%, rgba(0, 193, 255, 0.8) 108.71%), linear-gradient(124.9deg, rgba(0, 193, 255, 0) 42.77%, #00C1FF 97.84%), linear-gradient(106.54deg, rgba(85, 214, 255, 0.3) 7.75%, #00C1FF 89.78%);
        }

        .card__info {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #00C1FF 100%);
        }
    }

    &_green {
        .card__bg {
            background: linear-gradient(229.65deg, rgba(255, 255, 255, 0) 70.3%, rgba(102, 194, 15, 0.8) 108.71%), linear-gradient(124.9deg, rgba(163, 210, 13, 0) 42.77%, #66C20F 97.84%), linear-gradient(106.54deg, rgba(163, 210, 13, 0.3) 7.75%, #66C20F 89.78%);
        }

        .card__info {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #66C20F 100%);
        }
    }

    &_yellow {
        .card__bg {
            background: linear-gradient(229.65deg, rgba(255, 255, 255, 0) 70.3%, rgba(253, 201, 0, 0.8) 108.71%), linear-gradient(124.9deg, rgba(253, 201, 0, 0) 42.77%, #FDC900 97.84%), linear-gradient(106.54deg, rgba(253, 201, 0, 0.3) 7.75%, #FDC900 89.78%);
        }

        .card__info {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #FDC900 100%);
        }
    }

    &__bg {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.33;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    &__image {
        height: 234px;
        padding: 40px 40px 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        z-index: 3;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: 0.4s;
        }
    }

    &__info {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
        padding: 24px 28px;
        position: relative;
        z-index: 3;
    }

    &__row {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__name {
        flex-grow: 1;
        min-width: 0;
    }

    &__arrow {
        width: 40px;
        height: 40px;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        border: 1px solid #ffffff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        margin-left: 16px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &:hover {
        .card__arrow {
            opacity: 1;
        }
        .card__image {
            img {
                transform: scale(1.2) rotate(8deg);
            }
        }
    }


    @media screen and (max-width: 992px) {
        &__image {
            padding: 20px 20px 0;
        }

        &__info {
            padding: 12px 28px;
        }
    }

    @media screen and (max-width: 768px) {
        min-height: 192px;

        &__image {
            height: 144px;
        }

        &__arrow {
            display: none;
        }
    }
}